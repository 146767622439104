import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarTriggerDirective } from './sidebar-trigger.directive';
import { SidebarTemplateComponent } from './sidebar-template/sidebar-template.component';

@NgModule({
  declarations: [
    SidebarTriggerDirective,
    SidebarTemplateComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    SidebarTriggerDirective,
    SidebarTemplateComponent,
  ]
})
export class SidebarDirectiveModule { }
