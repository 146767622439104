import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, ViewContainerRef } from "@angular/core";
import { SidebarPanel } from "@app/shared/directives/sidebar-directive/sidebar-panel";
import { merge, Observable, Subscription } from "rxjs";
import { TemplatePortal } from "@angular/cdk/portal";
import { SidebarTriggerService } from "@app/shared/directives/sidebar-directive/sidebar-trigger.service";

/**
 * USAGE:
 * ---------------------------------------------------------------
   <div sidebarTriggerEl [sidebarTrigger]="sidebar">
    // Trigger Element
   </div>
   <sidebar-template #sidebar>
   // Your Template Here
   </sidebar-template>
 * */
@Directive({
  selector: '[sidebarTrigger]',
  host: {
    '(click)': 'toggleSidebar()'
  },
  exportAs: 'sidebarTrigger'
})
export class SidebarTriggerDirective implements OnInit{

  @Input('sidebarTrigger')
  public sidebarPanel!: SidebarPanel;

  @Input('open')
  public isOpen!: boolean;

  @Output()
  public onClose: EventEmitter<Event> = new EventEmitter<Event>();

  @Input('origin')
  public origin?: ElementRef<HTMLElement>;

  @Input()
  public hasBackdrop: boolean = false;

  private overlayRef!: OverlayRef;
  private sidebarClosingActionSub = Subscription.EMPTY;
  private static openedComponentRef: any = null;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private sidebarTriggerService: SidebarTriggerService
  ) { }

  ngOnInit(): void {
    // this.closeSidebar();
  }

  public toggleSidebar(): void {
    this.isOpen ? this.destroy() : this.openSidebar();
  }

  public openSidebar(): void {
    this.detachPreviousOverlay();
    if (this.overlayRef) { this.destroy() }

    this.isOpen = true;
    const overlayConfig = {...this.renderView(),
      hasBackdrop: this.hasBackdrop,
      backdropClass : 'cdk-overlay-transparent-backdrop'
    };

    this.overlayRef = this.overlay.create(overlayConfig);

    this.overlayRef.overlayElement.style.background = '#ffffff';
    this.overlayRef.overlayElement.style.boxShadow = '-4px 0px 10px 0px rgba(34, 34, 34, 0.1)';
    SidebarTriggerDirective.openedComponentRef = this.overlayRef;

    const templatePortal = new TemplatePortal(
      this.sidebarPanel.templateRef,
      this.viewContainerRef
    );

    this.overlayRef.attach(templatePortal)
    this.sidebarClosingActionSub = this.sidebarClosingActions()
      .subscribe(() => {
        this.destroy();
      })
  }

  private detachPreviousOverlay(): void {
    if (SidebarTriggerDirective.openedComponentRef !== null) {
      SidebarTriggerDirective.openedComponentRef.detach();
      SidebarTriggerDirective.openedComponentRef = null;
    }
  }

  public renderView(): OverlayConfig {
    if(this.origin && window.innerWidth < 1370) {
      return this.tabletView();
    }

    return this.desktopView()
  }

  public tabletView(): OverlayConfig {
    return {
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.origin!)
        .withPositions([{ originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' }]),
      height: this.origin?.nativeElement.offsetHeight
    }
  }

  public desktopView(): OverlayConfig {
    document.body.classList.add('overlay--showing');
    return  {
      positionStrategy: this.overlay.position().global().right('0').top('0'),
      height: '100vh'
    }
  }

  public sidebarClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const panelClosed = this.sidebarPanel.closed;

    return merge(backdropClick$, detachment$, panelClosed)
  }

  public destroy(): void {
    if(!this.overlayRef || !this.isOpen) {
      return
    }

    this.sidebarClosingActionSub.unsubscribe();
    this.isOpen = false;
    this.overlayRef.detach();
    this.onClose.emit();
    document.body.classList.remove('overlay--showing');
  }

  public closeSidebar(): void {
    this.sidebarTriggerService.closeSidebar.subscribe((closed: boolean) => {
      if(closed) {
        this.destroy();
        if (this.overlayRef) {
          this.overlayRef.dispose();
          this.overlayRef.detach();
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.sidebarTriggerService.closeSidebar.unsubscribe();
  }

}
