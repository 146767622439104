import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";

@Component({
  selector: 'sidebar-template',
  template:`
    <ng-template>
      <div class="sidebar">
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  styles:[`
    .sidebar {
      overflow-y: auto;
    }
  `]
})
export class SidebarTemplateComponent {

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;}
